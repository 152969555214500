
import { API_BASE_URL } from '../api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_CODE = 'SET_CODE';
export const USER = 'USER';

export const setUserId = (userId) => ({
  type: LOGIN,
  payload: { userId }
});

export const login = (email, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();

    
    if (data?.email && data?.userId) {
      dispatch({
        type: LOGIN,
        payload: { email: data.email, userId: data.userId, user:data  },
      });
      callback(null, data); 
    } else {
      callback(data?.message || data?.erro || 'Erro desconhecido', null);
    }
  } catch (error) {
    console.error('Erro no login: ', error);
    callback(error.message, null);
  }
};

export const logout = () => ({
  type: LOGOUT,
});

export const setCode = (code) => ({
  type: SET_CODE,
  payload: code,
});

export const verifyCode = (email, code) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, code }),
    });
    const data = await response.json();
    console.log("verifyCode",data)
    if (data && data?.userId) {
      dispatch({
        type: USER,
        payload: {...data.user, userId: data.userId},
      });
      window.dataSellTrack = window.dataSellTrack || [];
      window.dataSellTrack.push({ args: ["Login", { email }] });
      return data;
    
    }
    return false;
  } catch (error) {
    console.error('Erro na verificação do código: ', error);
    return false;
  }
};

export const signUp = (email, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    if (data?.email && data?.userId) {
      dispatch({
        type: LOGIN,
        payload: { email: data.email, userId: data.userId },
      });
      if (callback) callback(null, data);
      return data;
    } else {
      if (callback) callback(data?.message || data?.erro || 'Erro desconhecido', null);
      return false;
    }
  } catch (error) {
    console.error('Erro na inscrição: ', error);
    return false;
  }
};

export const registerDomain = (domain, callback) => async (dispatch) => {
  const email = localStorage.getItem('email'); 
  try {
    const response = await fetch(`${API_BASE_URL}/auth/register-domain`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, domain }),
    });
    const data = await response.json();
    console.log(data)
    if (data) {
      dispatch({
        type: USER,
        payload: data,
      });
      if (callback) callback(null, data);
    } else {
      if (callback) callback('Erro ao registrar domínio', null);
    }
  } catch (error) {
    console.error('Erro ao registrar domínio: ', error);
    if (callback) callback(error.message, null);
  }
};