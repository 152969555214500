import {
  SET_TRACKS,
  SET_CHART_DATA,
  SHOW_LOADER,
  HIDE_LOADER, LIST_WEBHOOK,
  LIST_TAKS
} from '../actions/dataActions';

const initialState = {
  tracks: [], // tracks inicializado como array
  chartData: [],
  loading: false,
  load_track: false,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRACKS:
      return { ...state, tracks: action.payload ? action.payload : []};
    case LIST_TAKS:
      return { ...state, tasks: action &&  action.payload ? action.payload: [] };
   case LIST_WEBHOOK:
        return { ...state, webhooks: action &&  action.payload ? action.payload: [] };
  
    case SET_CHART_DATA:
      return { ...state, chartData: action &&  action.payload ? action.payload: [] };
    case 'LOAD_TRACK':
      return { ...state, load_track : action.payload };
    case 'ADD_TRACK':
      return { ...state, tracks: [...state.tracks, action.payload] };
    case 'EDIT_TRACK':
      return {
        ...state, track : action.payload
    
      };
    case 'DELETE_TRACK':
      return {
        ...state,
        tracks: state.tracks.filter((track) => track["#rid"] !== action.payload),
      };
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default dataReducer;