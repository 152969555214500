import React, { useState, useEffect } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Autocomplete,
  Chip,
  Slider,
  Typography, Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AttributeModal from './AttributeModal';
import AttributeModalWebhook from './AttributeModalWebhook';

const EventModal = ({ open, onClose, onSave, eventData, pixels, handleDeleteEvent, type_tracking }) => {
  const [event, setEvent] = useState({
    tags: [],
    tipo_evento: '',
    numero_pixel: '',
    scroll_percentage: 0,
    atributos: []  // Lista de atributos adicionais
  });
  const [inputValue, setInputValue] = useState('');
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false);
  const [editingAttribute, setEditingAttribute] = useState(null);

  useEffect(() => {
    if (eventData) {
      setEvent({ ...eventData, tags: eventData.tags || [], atributos: eventData.atributos || [] });
    } else {
      setEvent({
        tags: [],
        tipo_evento: '',
        numero_pixel: '',
        scroll_percentage: 0,
        atributos: []
      });
    }
  }, [eventData]);

  const handleChange = (e) => {
    var { name, value } = e.target;
    if(value && name =="link_base64") value = paraBase64(value)
       
    setEvent(prevEvent => ({ ...prevEvent, [name]: value }));
    if(name =="link_base64"){
      setEvent(prevEvent => ({ ...prevEvent, link_monitorado:  e.target.value?.split("?")[0] }));
    }
  };

  const handleChangePixel = (e) => {
    const selectedPixel = pixels.find(pixel => pixel.numero_pixel === e.target.value);
    setEvent(prevEvent => ({
      ...prevEvent,
      numero_pixel: selectedPixel.numero_pixel,
      tipo_pixel: selectedPixel.tipo_pixel,
    }));
  };

  const handleSliderChange = (e, value) => {
    setEvent(prevEvent => ({ ...prevEvent, scroll_percentage: value }));
  };
  const handleSliderChangeDelay = (e, value) => {
    setEvent(prevEvent => ({ ...prevEvent, delay: value }));
  };

  const handleTagChange = (e, tags) => {
    setEvent(prevEvent => ({ ...prevEvent, tags }));
  };

  const handleSave = () => {
    if (!event.tags || event.tags.length === 0) return alert("Adicione um Evento");
    if (!event.tipo_evento) return alert("Adicione um tipo");
    if (!event.numero_pixel) return alert("Adicione um Pixel");
    console.log(event)
    onSave(event);
  };

  const handleOpenAttributeModal = (attribute = {}) => {
    console.log(attribute)
    setEditingAttribute(attribute);
    setIsAttributeModalOpen(true);
  };

  const handleCloseAttributeModal = () => {
    setEditingAttribute(null);
    setIsAttributeModalOpen(false);
  };

  const handleSaveAttribute = (attribute) => {
    setEvent(prevEvent => {
      const updatedAttributes = [...(prevEvent.atributos || [])];
      const attributeIndex = updatedAttributes.findIndex(attr => attr.id_inico === attribute.id_inico);
      if (attributeIndex > -1) {
        updatedAttributes[attributeIndex] = attribute;
      } else {
        updatedAttributes.push(attribute);
      }
      return { ...prevEvent, atributos: updatedAttributes };
    });
  };

  const handleDeleteAttribute = (id_inico) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      atributos: prevEvent.atributos.filter(attribute => attribute.id_inico !== id_inico)
    }));
  };

  const normalizeTag = (tag) => {
    if (!tag) return '';
    return tag.trim().replace(/\s+/g, '');
  };

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth={true} scroll={"body"} onClose={onClose}>
      <DialogTitle>{eventData ? 'Editar Evento' : 'Adicionar Novo Evento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{paddingTop:25}} sm={6}>
            <Autocomplete
              noOptionsText={"Escreva o evento e pressione enter"}
              multiple
              options={[" -- FACEBOOK ","PageView", "Lead","AddToCart", "InitiateCheckout", "Purchase", " --- TIKTOK ","Pageview", "CompleteRegistration", "AddToCart","InitiateCheckout", "CompletePayment"," --AG4","PageView", "Lead","AddToCart", "InitiateCheckout", "Purchase"]}
              value={event.tags && Array.isArray(event.tags) ? event.tags : []}
              name='tags'
              onChange={(_, newValue) => {
                const normalizedValues = newValue.map(tag => normalizeTag(tag)).filter(tag => tag.length > 0);
                handleTagChange(_, normalizedValues);
              }}
              filterSelectedOptions
              freeSolo
              renderTags={(value, getTagProps) => {
                if (!value || !Array.isArray(value)) return <div />;
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ));
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  {...params}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim().toLowerCase().replace(/\s+/g, '');
                    setInputValue(trimmedValue);
                  }}
                  placeholder="Escreva e aperte Enter..."
                  label="Rótulo de Eventos"
                />
              )}
            />

            <TextField
              name="tipo_evento"
              label="Gatilho do  Evento"
              select
              fullWidth
              margin="normal"
              value={event.tipo_evento}
              onChange={handleChange}
            >
              {type_tracking == "webhook" && (
                <MenuItem value="webhook">Webhook</MenuItem>
              )}
              {type_tracking == "web" && ( <MenuItem value="load">Carregamento de Página</MenuItem> )}
              {type_tracking == "web" && ( <MenuItem value="link">Click em Link</MenuItem> )}
              {type_tracking == "web" && ( <MenuItem value="scroll">Scroll</MenuItem> )}
              {type_tracking == "web" && ( <MenuItem value="timer">Timer</MenuItem> )}
              {type_tracking == "web" && ( <MenuItem value="form">Envio de Formulário</MenuItem> )}
              {/* {type_tracking == "web" && ( <MenuItem value="video">Play em Vídeo</MenuItem> )} */}
            </TextField>

            {event.tipo_evento === 'link' && (
              <TextField
                name="link_base64"
                label="Link Monitorado"
                fullWidth
                margin="normal"
                multiline
                value={event.link_base64 ? deBase64(event.link_base64) : event.link_monitorado || "" }
                onChange={handleChange}
                helperText="Adicione o link para ser monitorado. Se não definir nenhum link, qualquer link clicado vai disparar este evento."
              />
            )}

            {event.tipo_evento === 'scroll' && (
              <div>
                <Typography gutterBottom>
                  Defina a porcentagem de rolagem para disparar o evento
                </Typography>
                <Slider
                  name="scroll_percentage"
                  value={event.scroll_percentage}
                  onChange={handleSliderChange}
                  aria-labelledby="scroll-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={100}
                />
              </div>
            )}

{event.tipo_evento === 'timer' && (
              <div>
                <Typography gutterBottom>
                  Delay em Minutos
                </Typography>
                <Slider
                  name="delay"
                  value={event.delay}
                  onChange={handleSliderChangeDelay}
                  aria-labelledby="scroll-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={180}
                />
              </div>
            )}


            <TextField
              name="numero_pixel"
              label="Número do Pixel"
              select
              fullWidth
              margin="normal"
              value={event.numero_pixel}
              onChange={handleChangePixel}
            >
              {pixels.map(pixel => (
                <MenuItem key={pixel.numero_pixel} value={pixel.numero_pixel}>
                  {`${pixel.numero_pixel} - ${pixel.tipo_pixel}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={1} style={{ padding: 15 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid style={{paddingLeft:15}} item>
                  <Typography variant="h6">Atributos</Typography>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => handleOpenAttributeModal()}>
                    ADD
                  </Button>
                </Grid>
              </Grid>


              <List>
                {event.atributos && event.atributos.map((attribute) => (
                  <ListItem key={attribute.id_inico} button onClick={() => handleOpenAttributeModal(attribute)}>
                    <ListItemText
                      primary={attribute.name }
                      secondary={<span style={{
                        textWrap: "wrap" , 
                        maxWidth: "100%" , 
                        wordBreak: "break-all" , 
                      }}
                      >{attribute.value} </span>
                      }
                      
                    />
                    {attribute.make_hash && (
                      <Chip
                        color='success'
                        label={ "SHA256"}
                        size="small"
                      /> 
                    )}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleDeleteEvent(event.id_unico)}>
          Remover
        </Button>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSave} color="primary">{eventData ? 'Salvar' : 'Adicionar'}</Button>
      </DialogActions>

      {type_tracking == "webhook" ?(
        <AttributeModalWebhook
          open={isAttributeModalOpen}
          onClose={handleCloseAttributeModal}
          onSave={handleSaveAttribute}
          attribute={editingAttribute}
          type_tracking={type_tracking}
          handleDelete={handleDeleteAttribute}
        />
      ):(
        <AttributeModal
          open={isAttributeModalOpen}
          onClose={handleCloseAttributeModal}
          onSave={handleSaveAttribute}
          attribute={editingAttribute}
          type_tracking={type_tracking}
          handleDelete={handleDeleteAttribute}
        />
      )}
      
    </Dialog>
  );
};

export default EventModal;

function paraBase64(texto) {
  try {
      return btoa(texto);
  } catch (error) {
      console.error("Erro ao codificar para Base64:", error);
      return null;
  }
}

// Função para converter Base64 de volta para string
function deBase64(base64) {
  try {
      return atob(base64);
  } catch (error) {
      console.error("Erro ao decodificar de Base64:", error);
      return null;
  }
}