import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Alert
} from '@mui/material';

const PixelModal = ({ open, onClose, pixel, onSave, isEdit, userId, handleDeletePixel }) => {
  const [newPixel, setNewPixel] = useState(pixel);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    if (isEdit && pixel) {
      setNewPixel(pixel);
    } else {
      setNewPixel({ numero_pixel: '', token_pixel: '', tipo_pixel: '' });
    }
  }, [isEdit, pixel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPixel({ ...newPixel, [name]: value });
  };

  const handleSave = async () => {
    if (!newPixel.numero_pixel || !newPixel.token_pixel || !newPixel.tipo_pixel) {
      alert("Preencha todos os campos para continuar!");
      return;
    }

    setLoading(true);
    setErrorMessage('');

    onSave(newPixel, (data, error) => {
      setLoading(false);
      if (error) {
        setErrorMessage(error);
      } else {
        onClose();
      }
    });
  };

  const handleDelete = () => {
      handleDeletePixel(newPixel["@rid"]);
     
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {isEdit ? 'Editar Pixel' : 'Adicionar Novo Pixel'}
      </DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Número do Pixel/ID da Métrica"
              name="numero_pixel"
              fullWidth
              margin="normal"
              value={newPixel.numero_pixel}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Token do Pixel/Chave Secreta"
              name="token_pixel"
              fullWidth
              multiline
              margin="normal"
              value={newPixel.token_pixel}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Tipo do Pixel"
              name="tipo_pixel"
              fullWidth
              value={newPixel.tipo_pixel}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Facebook">Facebook</MenuItem>
              <MenuItem value="TikTok">TikTok</MenuItem>
              <MenuItem value="GA4">GA4</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {newPixel["@rid"] && (
          <Button onClick={handleDelete} color="primary">
            Deletar
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Salvar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PixelModal;