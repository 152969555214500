
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import { trim } from 'jquery';

const AttributeModal = ({ open, onClose, onSave, attribute, handleDelete, type_tracking}) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [dinamic, setDinamic] = useState(false);

  useEffect(() => {
    if (attribute) {
      setName(attribute.name || '');
      setValue(attribute.value || '');
      setDinamic(attribute.dinamic || false);
    } else {
      setName('');
      setValue('');
    }
  }, [attribute]);

  const handleSave = () => {
    if(!name|| !value ) return alert("Add Nome e Valor")
    onSave({ name, value, id_inico: attribute?.id_inico || uuidv4(), dinamic });
    onClose();
  };

  const handleRemove = () => {
    if(window.confirm("Deseja remover?")){
        handleDelete(attribute.id_inico);
        onClose();
    }
    
  };
  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setDinamic( checked  );
  };

  function normalizeString(str) {
    if(!str) return ""
    return str.trim()
      //.toLowerCase()                    // Converte para minúsculas
      .replace(/\s+/g, '')              // Remove espaços
      //.replace(/[^\w]/g, '');           // Remove caracteres especiais, mantendo apenas letras e números
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>{attribute ? 'Editar Atributo' : 'Adicionar Novo Atributo'}</DialogTitle>
      <DialogContent style={{paddingTop:25}}>
       
<Autocomplete
freeSolo
  label="Nome do Atributo"
  onChange={(event, newValue) => {
    newValue = typeof newValue === 'string' ? newValue.trim() : newValue;
    setName(newValue);
  }}
  value={normalizeString(name) }
  options={["currency","value","content_type","content_ids"]}
  renderInput={(params) => <TextField {...params}  onChange={(e) =>{
 
    setName(normalizeString(e.target.value))

  }} label="Nome do Atributo" />}
/>



        <TextField
          margin="normal"
          fullWidth
          label="Valor do Atributo"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          color= {type_tracking == "webhook" && dinamic ? "success" : "secondary" } 
          helperText= {type_tracking == "webhook" && dinamic ? "Adiciona o caminho do valor no JSON, ex: data.user.email " : "" } 
          InputProps={{
            endAdornment: (
              <div>
                {type_tracking == "webhook" && (
                  <Tooltip title="Valor dinâmico" >
              <Switch name='mask' color= {type_tracking == "webhook" && dinamic ? "success" : "secondary" }  checked={dinamic} onChange={handleCheckboxChange} />
              </Tooltip>
                )}
              </div>
            ),
          }}


        />
      </DialogContent>
      <DialogActions>
        {attribute && (
          <Button onClick={handleRemove} color="secondary">
            Remover
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeModal;