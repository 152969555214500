import { LOGIN, LOGOUT, SET_CODE, USER } from '../actions/authActions';

const initialState = {
  email: localStorage.getItem('email') || null,
  code: null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  userId: localStorage.getItem('userId') || null
};

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case USER:
      localStorage.setItem('user', JSON.stringify(action.payload));
      // Update timestamp on every user action
      localStorage.setItem('lastLoginTime', new Date().toISOString());
      return { ...state, user: action.payload };

    case LOGIN:
      localStorage.setItem('email', action.payload.email);
      localStorage.setItem('userId', action.payload.userId);
      localStorage.setItem('lastLoginTime', new Date().toISOString());

      return { ...state, email: action.payload.email, userId: action.payload.userId };

    case LOGOUT:
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('userId');
      localStorage.removeItem('lastLoginTime');
      return { ...state, email: null, code: null, user: null, userId: null };

    case SET_CODE:
      return { ...state, code: action.payload };

    default:
      return state;
  }
};

export default authReducer;