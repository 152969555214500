
import React from 'react';
import Typography from '@mui/material/Typography';

const Footer = () => (
  <footer style={{ marginTop: '50px', textAlign: 'center', padding: '10px', borderTop: '1px solid rgb(221 221 221 / 40%)' }}>
    <Typography variant="body2" color="textSecondary" align="center" style={{marginTop:30}}>
      {'Todos os direitos reservados © '}
      SELLFLUX {new Date().getFullYear()}.
    </Typography>
  </footer>
);

export default Footer;