import { API_BASE_URL } from '../api';

export const SET_TRACKS = 'SET_TRACKS';
export const SET_CHART_DATA = 'SET_CHART_DATA';
export const LIST_TAKS = 'LIST_TAKS';
export const LIST_WEBHOOK = 'LIST_WEBHOOK';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const setTracks = (userId, callback) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/tracks`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      }
    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: SET_TRACKS,
        payload: data,
      });
      callback(null, data);
    }
  } catch (error) {
    console.error('Set tracks error: ', error);
    callback(error, null);
  } finally {
    dispatch(hideLoader());
  }
};

export const addTrack = (userId, track, callback) => async (dispatch) => {
  // dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/tracks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify(track),
    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: 'ADD_TRACK',
        payload: data.track,
      });
      callback(null, data?.track?.result[0]);
    }
  } catch (error) {
    console.error('Add track error: ', error);
    callback(error, null);
  } finally {
    // dispatch(hideLoader());
  }
};

export const editTrack = (userId, track, callback) => async (dispatch) => {
  dispatch(showLoader());
  console.log(track)
  console.log(track["@rid"])
  try {
    const response = await fetch(`${API_BASE_URL}/tracks/edit`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify(track),
    });
    const data = await response.json();
    console.log(data)
    if (data) {
      dispatch({
        type: 'EDIT_TRACK',
        payload: data.track,
      });
      callback(null, data);
    }
  } catch (error) {
    console.error('Edit track error: ', error);
    callback(error, null);
  } finally {
    dispatch(hideLoader());
  }
};

export const getTrack = (userId, track, callback) => async (dispatch) => {
  // dispatch(showLoader());
  // console.log(track)
  console.log(track["@rid"], userId)
  dispatch({
    type: 'LOAD_TRACK',
    payload: true,
  });

  try {
    const response = await fetch(`${API_BASE_URL}/tracks/item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify(track),
    });
    const data = await response.json();
    console.log("getTrack",data)
    if (data) {
      dispatch({
        type: 'EDIT_TRACK',
        payload: data,
      });
      dispatch({
        type: 'LOAD_TRACK',
        payload: false,
      });
      callback(null, data);
    }
  } catch (error) {
    console.error('Edit track error: ', error, track);
    callback(error, null);
  } finally {
    // dispatch(hideLoader());
  }
};


export const deleteTrack = (userId, id, callback) => async (dispatch) => {
  // dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/tracks/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify({id}),

    });
    const data = await response.json();
    if (data) {
      dispatch({
        type: 'DELETE_TRACK',
        payload: id,
      });
      callback(null, data);
    }
  } catch (error) {
    console.error('Delete track error: ', error);
    callback(error, null);
  } finally {
    // dispatch(hideLoader());
  }
};


export const fetchChartData = (userId) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await fetch(`${API_BASE_URL}/tasks/last30days`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      }
    });
    const data = await response.json();
    // console.log(data)
    if (data) {
      dispatch({
        type: SET_CHART_DATA,
        payload: data,
      });
    }
  } catch (error) {
    console.error('Failed to fetch chart data: ', error);
  } finally {
    dispatch(hideLoader());
  }
};

export const getTasks = (userId, hash_track, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tasks/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify({hash_track,type:"pixel"}),
    });
    const data = await response.json();
    console.log("LIST_TAKS",data)
    if (data) {
      dispatch({
        type: LIST_TAKS,
        payload: data,
      });
    }
    if(callback) callback(null,data)
  } catch (error) {
    console.error('Failed to fetch chart data: ', error);
  } finally {
  }
};
export const getWebhooks = (userId, hash_track, callback) => async (dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tasks/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId
      },
      body: JSON.stringify({hash_track,type:"webhook"}),
    });
    const data = await response.json();
    console.log("LIST_TAKS",data)
    if (data) {
      dispatch({
        type: LIST_WEBHOOK,
        payload: data,
      });
    }
    if(callback) callback(null,data)
  } catch (error) {
    console.error('Failed to fetch chart data: ', error);
  } finally {
  }
};
export const setChartData = (data) => ({
  type: SET_CHART_DATA,
  payload: data,
});

export const showLoader = () => ({
  type: SHOW_LOADER,
});

export const hideLoader = () => ({
  type: HIDE_LOADER,
});