import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';

const TermsOfUseDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Termos de Uso e Políticas de Privacidade</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="div">
          <Typography sx={{ mt: 2 }}>
            <strong>1. Descrição do Serviço</strong><br />
            SellTracking é um serviço gratuito oferecido pela Sellflux. Trata-se de um script que você pode instalar em suas páginas de vendas, captação e outras afins para inserir scripts do Facebook Pixel e TikTok Pixel. Ele dispara eventos diretamente no navegador e também na API de conversão no backend.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>2. Uso do Serviço</strong><br />
            Ao utilizar o SellTracking, você concorda em:<br />
            - Utilizar o serviço apenas para fins legais e em conformidade com todas as leis aplicáveis.<br />
            - Não modificar, redistribuir ou revender o serviço.<br />
            - Garantir que suas páginas de vendas e captação onde o script será inserido estão em conformidade com todas as disposições legais e regulatórias.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>3. Dados e Privacidade</strong><br />
            - Os dados coletados e processados pelo SellTracking serão utilizados exclusivamente para as funcionalidades do serviço, como monitoramento de conversões e disparo de eventos.<br />
            - Sellflux garante que não utilizará, venderá ou compartilhará seus dados para finalidades fora do escopo deste serviço sem seu consentimento explícito.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>4. Limitação de Responsabilidade</strong><br />
            - Sellflux fornece SellTracking "como está" e "conforme disponível", sem garantias de qualquer tipo, expressas ou implícitas.<br />
            - Sellflux não será responsável por quaisquer danos diretos, indiretos, incidentais, consequenciais ou especiais decorrentes ou relacionados ao uso do SellTracking.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>5. Modificações e Descontinuação</strong><br />
            - Sellflux se reserva o direito de modificar ou descontinuar o SellTracking a qualquer momento, sem aviso prévio.<br />
            - Alterações nos Termos de Uso poderão ser feitas e, nesse caso, serão notificadas aos usuários.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>6. Contato e Suporte</strong><br />
            Para dúvidas e suporte técnico, entre em contato conosco através do e-mail contato@sellflux.app.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Ao utilizar o SellTracking, você concorda com estes Termos de Uso. Se não concordar com qualquer parte destes termos, por favor, não utilize o SellTracking.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <em>Data de Vigência: [Inserir data]</em>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <strong>Sellflux - Powered by Innovation</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfUseDialog;