
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { verifyCode } from '../actions/authActions';

const VerifyCode = () => {
  const [inputCode, setInputCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.auth.email);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleVerify = async () => {
    setLoading(true);
    const isVerified = await dispatch(verifyCode(email, inputCode));
    setLoading(false);

    if (isVerified.email) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user?.domain) {
        navigate('/dashboard');
      } else {
        navigate('/register-domain');
      }
    } else {
      setErrorMessage('Código incorreto. Por favor, tente novamente.');
    }
  };

  if (!email) {
    window.location.href = "/";
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Typography component="h1" variant="h5">
              Verificação de Código
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="code"
                label="Código"
                name="code"
                autoComplete="code"
                autoFocus
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}

              {loading ? (
                <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleVerify}
                    disabled={loading}
                  >
                    Verificar
                  </Button>

                  <Button
                    fullWidth
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => navigate('/')}
                    disabled={loading}
                  >
                    Voltar para Login
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default VerifyCode;