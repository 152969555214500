
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Button, Chip , LinearProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {getWebhooks} from '../actions/dataActions';
import moment from 'moment';
const momentTimezone = require('moment-timezone');

const HistoryDialog = ({ open, onClose, track, userId }) => {
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    const [load, setLoad] = useState(true);

    const timezone = momentTimezone.tz.guess();
    // console.log(timezone)
    React.useEffect(() => {
        // console.log(track, userId)
        if(track["@rid"] && open) {
          dispatch(getWebhooks(userId,track.hash_track , (error, data) => {
        //    console.log(error,data)
           setRecords(data)
           setLoad(false)
          }));
        } 
      }, [track["@rid"]]);
// console.log(records)
  return (
    <Dialog open={open} onClose={onClose} 
    
    maxWidth={"md"} fullWidth={true} scroll={"body"}>
      
      <DialogTitle>Últimos Registros Enviados pelo Servidor</DialogTitle>
      <DialogContent>
        {!load && records.length ==0 && <div>Sem registros no momento!</div>}
        {load  && <LinearProgress />}
        <List>
          {records?.map((record, index) => (
            <ListItem key={index}>
              <ListItemText primary={record.hash_track} secondary={
                <div>
                    {record.method && (<span style={{marginRight:10}}> <Chip label={record.method} size='small'  color={record.method =="lead" ? "success": "primary"} /></span>) }
                    {moment.utc(record.created_at, "YYYY-MM-DD HH:mm:ss").tz(timezone).fromNow()}  
                    <pre id="json-container" style={{
                     
                    }}>{ getObjectPaths( JSON.parse(record.body ||"{}") ).join('\n')  }</pre>
                </div>
              } />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};


function getObjectPaths(obj, prefix = '') {
  let paths = [];
  if(!obj) return paths
  for (const [key, value] of Object.entries(obj)) {
    const newPath = Array.isArray(obj) ? `${prefix}[${key}]` : (prefix ? `${prefix}.${key}` : key);
    
    if (typeof value === 'object' && value !== null) {
      paths = paths.concat(getObjectPaths(value, newPath));
    } else {
      paths.push(`${newPath} = ${value}`);
    }
  }
  
  return paths;
}


export default HistoryDialog;