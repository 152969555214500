import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, Checkbox, FormControlLabel, List,
  Card, CardContent, CircularProgress, ListItem, ListItemText , ListItemSecondaryAction , IconButton} from '@mui/material';
import { signUp } from '../actions/authActions';
import PrivacyPolicyModal from './PrivacyPolicyModal';

const SignUp = () => {

  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Novo estado para controlar o loading
  const [isPolicyModalOpen, setPolicyModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (!email || !checked) {
      setErrorMessage('Por favor, preencha todos os campos e aceite os termos.');
      return;
    }

    setLoading(true); // Setar o estado para true para começar o loading

    const success = await dispatch(signUp(email));
    setLoading(false); // Parar o loading depois da resposta do servidor
    
    if (success) {
      navigate('/verify');
    } else {
      setErrorMessage('Erro na inscrição. Por favor, tente novamente.');
    }
  };

  return (
    <Container    maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: 400 }}>
          <CardContent>
            <Typography component="h1" variant="h5">
              Inscrição Grátis
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />


<List style={{width:"100%"}}>
                  <ListItem>
                  <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                   
      <ListItemText
        primary="Eu aceito os"
        secondary={
           " Termos e Políticas de Privacidade"
        }
      />
       <ListItemSecondaryAction>
        <IconButton edge="end" onClick={() => setPolicyModalOpen(true)}>
          Ler
        </IconButton>
      </ListItemSecondaryAction>

    </ListItem>
    </List>
           
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}
              
              {loading ? (
                <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSignUp}
                  disabled={loading} // Desativa o botão enquanto loading é true
                >
                  Inscrever-se
                </Button>
              )}

              <Button fullWidth variant="text" onClick={() => navigate('/')}>
                Já tenho uma conta
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <PrivacyPolicyModal
        open={isPolicyModalOpen}
        onClose={() => setPolicyModalOpen(false)}
        handleClose={() => setPolicyModalOpen(false)}
      />
    </Container>
  );
};

export default SignUp;