import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Chip,
  Grid,
  Box,
  Icon,
  Paper,
  Divider,
  Tooltip,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DnsConfigModal = ({ open, onClose, ipv4, ipv6 }) => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copiado para a área de transferência!");
  };
  const handleDomainChange = () => {
    navigate('/register-domain');
    onClose()
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h5" component="div">
          Configuração de IPs para 
          <Chip
            label={user?.domain}
            onClick={() => copyToClipboard(user?.domain)}
            clickable
            size="large"
            style={{ marginLeft: 10 }}
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
          <Box mb={2}>
            <Typography style={{ color: "#777" }} variant="h6">
              Configuração DNS Tipo [A] <Chip label={"IPV4"} size="large" style={{ marginLeft: 10 }} />
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <Box display="flex" alignItems="center" mt={1}>
              <Typography>Aponte os seu registro para o seguinte IP: </Typography>
              <Tooltip title="Clique para copiar">
                <Chip
                  label={ipv4}
                  color="primary"
                  onClick={() => copyToClipboard(ipv4)}
                  clickable
                  style={{ marginLeft: 10 }}
                />
              </Tooltip>
            </Box>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  label="Tipo"
                  value="A"
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title="Clique para copiar">
                        <Icon onClick={() => copyToClipboard("A")} style={{ cursor: "pointer" }} fontSize="small">
                          content_copy
                        </Icon>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              {/*<Grid item xs={12} sm={4} md={3}>
              <TextField
                label="Prefixo ou Nome"
                value={domain.startsWith("www.") ? "www" : "@"}
                variant="outlined"
                fullWidth
                size="small"
                disabled
                margin="dense"
              />
            </Grid>*/}
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  label="Valor"
                  value={ipv4}
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title="Clique para copiar">
                        <Icon onClick={() => copyToClipboard(ipv4)} style={{ cursor: "pointer" }} fontSize="small">
                          content_copy
                        </Icon>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper elevation={3} style={{ padding: 16 }}>
          <Box>
            <Typography style={{ color: "#777" }} variant="h6">
              Configuração DNS Tipo [AAAA] <Chip label={"IPV6"} size="large" style={{ marginLeft: 10 }} />
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <Box display="flex" alignItems="center" mt={1}>
              <Typography>Aponte os seu registro para o seguinte IP: </Typography>
              <Tooltip title="Clique para copiar">
                <Chip
                  label={ipv6}
                  color="primary"
                  onClick={() => copyToClipboard(ipv6)}
                  clickable
                  style={{ marginLeft: 10 }}
                />
              </Tooltip>
            </Box>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  label="Tipo"
                  value="AAAA"
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title="Clique para copiar">
                        <Icon onClick={() => copyToClipboard("AAAA")} style={{ cursor: "pointer" }} fontSize="small">
                          content_copy
                        </Icon>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              {/*<Grid item xs={12} sm={4} md={3}>
              <TextField
                label="Prefixo ou Nome"
                value={domain.startsWith("www.") ? "www" : "@"}
                variant="outlined"
                fullWidth
                size="small"
                disabled
                margin="dense"
              />
            </Grid>*/}
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  label="Valor"
                  value={ipv6}
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title="Clique para copiar">
                        <Icon onClick={() => copyToClipboard(ipv6)} style={{ cursor: "pointer" }} fontSize="small">
                          content_copy
                        </Icon>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
      <Button color="primary" onClick={handleDomainChange}>
          Mudar Domínio
        </Button>

        <Button color="secondary" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DnsConfigModal;