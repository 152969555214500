import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import SignUp from './components/SignUp';
import VerifyCode from './components/VerifyCode';
import Header from './components/Header';
import { lightTheme, darkTheme } from './theme';
import RegisterDomain from './components/RegisterDomain';  // Novo componente
import { useSelector, useDispatch } from 'react-redux';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(true); 
  const user = useSelector((state) => state.auth.user);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const hasValidDomain = user?.domain; // Supondo que o domain é uma string não vazia para ser válido
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<VerifyCode />} />
          <Route path="/register-domain" element={user ? <RegisterDomain />: <Navigate to="/login" />} /> {/* Novo Roteamento */}
          <Route
            path="/dashboard"
            element={hasValidDomain ? <Dashboard /> : <Navigate to="/register-domain" />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;