
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import './index.css'; 

const LOGIN_TIMEOUT_HOURS = 24;

const checkLoginTimeout = () => {
  const lastLoginTime = localStorage.getItem('lastLoginTime');
  if (lastLoginTime) {
    const currentTime = new Date().getTime();
    const lastTime = new Date(lastLoginTime).getTime();
    const hoursSinceLastLogin = (currentTime - lastTime) / 1000 / 60 / 60;

    if (hoursSinceLastLogin > LOGIN_TIMEOUT_HOURS) {
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('lastLoginTime');
    }
  }
};

checkLoginTimeout();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);