
import {
    SET_PIXELS,
    ADD_PIXEL,
    EDIT_PIXEL,
    DELETE_PIXEL,
    SHOW_PIXEL_LOADER,
    HIDE_PIXEL_LOADER
  } from '../actions/pixelActions';
  
  const initialState = {
    pixels: [],
    loading: false,
  };
  
  const pixelReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PIXELS:
        return { ...state, pixels: action.payload };
      case ADD_PIXEL:
        return { ...state, pixels: [...state.pixels  ] };
      case EDIT_PIXEL:
        return {
          ...state,
          pixels: state.pixels.map((pixel) =>
            pixel.id === action.payload.id ? action.payload : pixel
          ),
        };
      case DELETE_PIXEL:
        return {
          ...state,
          pixels: state.pixels.filter((pixel) => pixel.id !== action.payload),
        };
      case SHOW_PIXEL_LOADER:
        return { ...state, loading: true };
      case HIDE_PIXEL_LOADER:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default pixelReducer;