import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { fetchChartData } from '../actions/dataActions';
const moment = require('moment');
const momentTimezone = require('moment-timezone');

// Registrando os componentes necessários
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.data.chartData);
  const userId = useSelector((state) => state.auth.userId);

  useEffect(() => {
    if (userId) {
      dispatch(fetchChartData(userId));
    }
  }, [dispatch, userId]);

  if (!chartData || !chartData.map) return <div>Carregando...</div>;

  const data = {
    labels: chartData.map((d) => moment(d.time).local().format('DD HH[H]')),
    datasets: [
      {
        label: 'Total de Eventos',
        data: chartData.map((d) => d.totalTasks),
        backgroundColor: 'rgba(75, 192, 192, 0.4)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const totalTasks = context.raw;
            return `${totalTasks} eventos disparados no servidor de API de conversão`;
          },
        },
      },
      legend: {
        display: false, // Ajuste para exibir a legenda se necessário
      },
    },
    maintainAspectRatio: false, // Importante para permitir controle total da altura
    responsive: true,
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default Chart;