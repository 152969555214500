import React, { useState } from 'react';
import { Dialog, CircularProgress, AlertTitle, DialogTitle, Tooltip,IconButton,
  DialogActions, DialogContent, Grid, Paper, TextField, Button, ListItemSecondaryAction,  Icon, MenuItem, OutlinedInput, FormControlLabel, Checkbox, Box, Chip, Select, List, ListItem, ListItemText, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Autocomplete from '@mui/material/Autocomplete';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, useDispatch } from 'react-redux';
import { getTrack } from '../actions/dataActions';
import DownloadIcon from '@mui/icons-material/Download'; // Importar ícone de exportar
import UploadIcon from '@mui/icons-material/Upload'; // Importar ícone de importar

import HistoryDialog from './HistoryDialog';
import HistoryWebhooks from './HistoryWebhooks';
import ImportModal from './ImportModal'; // Importar o modal de importar 
import DuplicateIcon from '@mui/icons-material/ContentCopy'; // ou o ícone que você escolher
import EventModal from './EventModal';
import { v4 as uuidv4 } from 'uuid';

const TrackModal = ({ open, onClose, track, onSave, isEdit, userId, type_tracking,  handleDeleteTrack }) => {
  const dispatch = useDispatch();
  const [newTrack, setNewTrack] = useState(track);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [isCopySuccess2, setIsCopySuccess2] = useState(false);
  const load_track = useSelector(state => state.data.load_track);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [isHistoryDialogOpenWebhook, setIsHistoryDialogOpenWebhook] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const pixels = useSelector(state => state.pixel.pixels);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false); // Estado para controlar o modal de importação
  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    if (track["@rid"]) {
      dispatch(getTrack(userId, track, (error, data) => {
        setNewTrack(data);
        setIsFirstStep(false);
      }));
    } else {
      setNewTrack({});
      setIsFirstStep(true);
    }
  }, [track["@rid"]]);


  const handleDuplicateEvent = (event) => {
    const duplicatedEvent = { ...event, id_unico: uuidv4() }; // Clonamos o evento e geramos um novo id
    setNewTrack(prevState => ({
      ...prevState,
      events: [...(prevState.events || []), duplicatedEvent] // Adicionamos o evento duplicado à lista
    }));
  };


  const handleChange = e => {
    const { name, value } = e.target;
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    setNewTrack({ ...newTrack, [name]: trimmedValue });
  };

  const handleChangeName = e => {
    const { name, value } = e.target;
    setNewTrack({ ...newTrack, [name]: value });
  };

  const handleSaveTrack = () => {
    if (!newTrack.name || (!newTrack.domains && type_tracking =="web")) {
      alert("Preencha todos os campos obrigatórios");
      return;
    }
    onSave(newTrack, data => {
      setNewTrack(data);
      setIsFirstStep(false);
    });
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setNewTrack({ ...newTrack, [name]: checked });
  };

  const handleExportEvents = () => {
    const fileName =  type_tracking+"_events.json";
    const json = JSON.stringify(newTrack.events, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.target ="new"
    link.click();
    document.body.removeChild(link);
  };
  const handleOpenImportModal = () => {
    setIsImportModalOpen(true);
  };

  const handleCloseImportModal = () => {
    setIsImportModalOpen(false);
  };
  const handleImportEvents = (importedEvents) => {
    console.log(importedEvents)
      // Verifica se newTrack.events já está definido, e se não, inicializa como um array vazio
      const existingEvents = newTrack.events || [];
    
      // Cria um conjunto de IDs existentes para maior eficiência na verificação
      const existingEventIds = new Set(existingEvents.map(event => event.id_unico));
    
      // Filtra os novos eventos apenas para aqueles que não estão nos eventos existentes
      const newEvents = importedEvents.filter(importedEvent => !existingEventIds.has(importedEvent.id_unico));
    
      // Atualiza o estado com os eventos existentes e os novos eventos
      setNewTrack(prevState => ({
        ...prevState,
        events: [...(prevState.events || []), ...newEvents],
      }));
    
      // Fecha o modal de importação
    handleCloseImportModal();
  };


  const handleOpenEventModal = (event = null) => {
    console.log(event)
    setCurrentEvent(event);
    setIsEventModalOpen(true);
  };

  const handleCloseEventModal = () => {
    setCurrentEvent(null);
    setIsEventModalOpen(false);
  };

  const handleSaveEvent = (event) => {
    setNewTrack(prevState => {
      const updatedEvents = [...(prevState.events || [])];
      if (event.id_unico) {
        const eventIndex = updatedEvents.findIndex(evt => evt.id_unico === event.id_unico);
        if (eventIndex > -1) {
          updatedEvents[eventIndex] = event;
        } else {
          updatedEvents.push(event);
        }
      } else {
        event.id_unico = uuidv4();
        updatedEvents.push(event);
      }
      return { ...prevState, events: updatedEvents };
    });
    handleCloseEventModal();
  };

  const handleDeleteEvent = (id_unico) => {
    if(window.confirm("Deseja Deletar este evento?")){
      setNewTrack(prevState => ({
        ...prevState,
        events: prevState.events.filter(event => event.id_unico !== id_unico)
      }));
      handleCloseEventModal()
    }
      
  };

  const handleChangeDomain = (e, b) => {
    setNewTrack({
      ...newTrack,
      domains: [...getDomainsFromUrls(b)],
    });
  };

  const handleChangeEventPixel = (field) => (e, b, v) => {
    setNewTrack({
      ...newTrack,
      [field]: [...b],
    });
  };

  const getCodeProprio = (track) => {
    if(!user) return     ``
    return ` <script src="https://${user.domain}/pixel/sellflux/${track.hash_track}/track.js" id="SellFlux.com" defer></script> `
  }


  const getCode = (track) => {
    if(type_tracking == "webhook") return     `https://app.selltracking.com/webhook/sellflux/${track.hash_track}/track`
    return `  <script src="https://app.selltracking.com/pixel/sellflux/${track.hash_track}/track.js" id="SellFlux.com" defer></script> `
  }

  const handleCopyScript = (track) => {
    const scriptText = getCode(track);
    navigator.clipboard.writeText(scriptText)
      .then(() => {
        setIsCopySuccess(true);
        setTimeout(() => setIsCopySuccess(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleCopyScriptProprio = (track) => {
    const scriptText = getCodeProprio(track);
    navigator.clipboard.writeText(scriptText)
      .then(() => {
        setIsCopySuccess2(true);
        setTimeout(() => setIsCopySuccess2(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };
  


  return (
    <Dialog open={open} maxWidth={"md"} fullWidth={true} scroll={"body"}>
      {isHistoryDialogOpen &&
        <HistoryDialog
          open={isHistoryDialogOpen}
          onClose={() => setIsHistoryDialogOpen(false)}
          userId={userId}
          track={newTrack}
        />
      }

{isHistoryDialogOpenWebhook &&
        <HistoryWebhooks
          open={isHistoryDialogOpenWebhook}
          onClose={() => setIsHistoryDialogOpenWebhook(false)}
          userId={userId}
          track={newTrack}
        />
      }

      <DialogTitle>{isEdit ? 'Editar Track' : 'Adicionar Nova Track'}
        {load_track === false && newTrack["@rid"] && (
          <Button color="primary" style={{ marginTop: '0px', float: "right" }} onClick={() => setIsHistoryDialogOpen(true)}>
            VER HISTÓRICO : ( {newTrack.totalTasks} )
          </Button>
        )}
        {load_track === false && newTrack["@rid"] && type_tracking =="webhook" && (
          <Button color="primary" style={{ marginTop: '0px', float: "right" }} onClick={() => setIsHistoryDialogOpenWebhook(true)}>
            WEBHOOKS
          </Button>
        )}

      </DialogTitle>
      {load_track ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome do Rastreamento"
                name="name"
                fullWidth
                margin="normal"
                value={newTrack.name}
                onChange={handleChangeName}
              />
              <div style={{ height: 20 }}></div>
               {type_tracking == "web" && (
              <Autocomplete
                noOptionsText="Nenhum domínio no projeto"
                multiple
                options={[]}
                value={newTrack?.domains && Array.isArray(newTrack.domains) ? newTrack?.domains : []}
                onChange={handleChangeDomain}
                filterSelectedOptions
                freeSolo
                renderTags={(value, getTagProps) => {
                  if (!value || !value.map) return <div />;
                  if (!Array.isArray(value)) return <div />;
                  return value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    InputProps={{}}
                    {...params}
                    helperText="Adicione e aperte Enter para gravar"
                    label="Domínios Permitidos"
                  />
                )}
              />
            )}
  {type_tracking == "web" && (
    <>
              <div style={{ height: 20 }}></div>
              <Alert icon={<WhatsAppIcon />} action={
                <Switch name='mask' checked={newTrack.mask} onChange={handleCheckboxChange} />
              }>
                <AlertTitle>Aplicar máscara de Telefone</AlertTitle>
                {newTrack.mask && <span style={{ fontSize: 12, opacity: 0.6 }}>Será aplicado máscara por DDI do país</span>}
              </Alert>
              <div style={{ height: 20 }}></div>
              <Alert icon={<AddLinkIcon />} action={
                <Switch name='link_gets' checked={newTrack.link_gets} onChange={handleCheckboxChange} />
              }>
                <AlertTitle>Manter os parâmetros GET/UTM do navegador ao clicar nos links ou Formulários</AlertTitle>
                {newTrack.link_gets && <span style={{ fontSize: 12, opacity: 0.6 }}>Todos os parâmetros do navegador serão encaminhados para página destino para manter o rastreamento de UTMS</span>}
              </Alert>
              <div style={{ height: 20 }}></div>
              <Alert icon={<AddLinkIcon />} action={
                <Switch name='link_form' checked={newTrack.link_form} onChange={handleCheckboxChange} />
              }>
                <AlertTitle>Adicionar os dados (nome, email, telefone) como parâmetro ao enviar o Formulário</AlertTitle>
                {newTrack.link_form && <span style={{ fontSize: 12, opacity: 0.6 }}>Para quem deseja ter um Checkout pré-povoado etc...</span>}
              </Alert>
              </>
)}
              {!isFirstStep && (
                <Paper elevation={1} style={{ marginTop: 30, padding: 15, position: 'relative' }}>
                  <AlertTitle style={{ fontSize: 14, color: "#999" }}>Código de Rastreamento: </AlertTitle>
                  <Box>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginTop: 30 }}>
                      {getCode(newTrack)}
                    </pre>
                    <Button startIcon={isCopySuccess ? <CheckCircleIcon /> : <ContentCopyIcon />}
                      variant="outlined" color={isCopySuccess ? "success" : "primary"}
                      onClick={() => handleCopyScript(newTrack)}
                      style={{ position: 'absolute', top: 10, right: 10 }}>
                      {isCopySuccess ? 'Copiado!' : 'Copiar'}
                    </Button>
                  </Box>
                </Paper>
              )}

            {!isFirstStep && user && user.domain && type_tracking !="webhook" && (
                <Paper elevation={1} style={{ marginTop: 30, padding: 15, position: 'relative' }}>
                  <AlertTitle style={{ fontSize: 14, color: "#999" }}>Script Próprio de Rastreamento: <br></br> Colar no Body da página</AlertTitle>
                  <Box>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginTop: 30 }}>
                      {getCodeProprio(newTrack)}
                    </pre>
                    <Button startIcon={isCopySuccess2 ? <CheckCircleIcon /> : <ContentCopyIcon />}
                      variant="outlined" color={isCopySuccess2 ? "success" : "primary"}
                      onClick={() => handleCopyScriptProprio(newTrack)}
                      style={{ position: 'absolute', top: 10, right: 10 }}>
                      {isCopySuccess2 ? 'Copiado!' : 'Copiar'}
                    </Button>
                  </Box>
                </Paper>
              )}

            </Grid>
            <Grid item xs={12} sm={6}>
            
                <Paper elevation={1} style={{ padding: 15, marginTop:15 }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid  style={{paddingLeft:15}} item>
                      <Typography variant="h6">Eventos</Typography>
                    </Grid>
                 
                 
                    <Grid item>
                    <Tooltip title="Exportar">
                      <IconButton color="primary" onClick={handleExportEvents}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                 
                  <Grid item>
                    <Tooltip title="Importar">
                      <IconButton color="primary" onClick={handleOpenImportModal}>
                        <UploadIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
               
                  <Grid item>
                    <Tooltip title="Adicionar Evento">

                      <Button variant="contained" color="primary" onClick={() => handleOpenEventModal({})}>
                        ADD
                      </Button>
                    </Tooltip>
                    </Grid>

                  </Grid>
                  <List>
                    {newTrack?.events && newTrack.events.map(event => (
                      <ListItem key={event.id_unico} button>
                        <ListItemText
                          primary={getLabelEvent(event)}
                          secondary={getDescription(event)}
                          onClick={() => handleOpenEventModal(event)}
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => handleDuplicateEvent(event)}>
                            <DuplicateIcon /> {/* Você pode usar um ícone apropriado aqui, como um ícone de duplicar */}
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
             
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        {newTrack["@rid"] &&
          <Button color="primary" style={{ marginTop: '20px' }} onClick={() => handleDeleteTrack(newTrack["@rid"])}>Deletar</Button>}
        <Button color="primary" onClick={onClose} style={{ marginTop: '20px' }}>Fechar</Button>
        <Button variant="contained" color="primary" onClick={handleSaveTrack} style={{ marginTop: '20px' }}>Salvar</Button>
      </DialogActions>
      {isEventModalOpen && (
        <EventModal
          open={isEventModalOpen}
          onClose={handleCloseEventModal}
          type_tracking={type_tracking}
          onSave={handleSaveEvent}
          handleDeleteEvent={handleDeleteEvent}
          eventData={currentEvent}
          pixels={pixels}
        />
      )}
      {isImportModalOpen && (
        <ImportModal
          open={isImportModalOpen}
          onClose={handleCloseImportModal}
          onImport={handleImportEvents}
        />
      )}

    </Dialog>
  );
};

export default TrackModal;
function getDescription(event){
  if(event.tipo_evento =="link" && !event.link_monitorado) return <span> {event.numero_pixel} <br></br>  Todos os Links <br></br>  {renderTags(event.tags, event.tipo_pixel) }</span>
  if(event.tipo_evento =="link") return <span> {event.numero_pixel} <br></br>  {event.link_monitorado} <br></br>  {renderTags(event.tags, event.tipo_pixel ) }</span>
  return <span> {event.numero_pixel} <br></br>  {renderTags(event.tags,event.tipo_pixel) } </span>

}
const getColorForPixel = (tipo_pixel) => {
  switch(tipo_pixel) {
    case 'Facebook':
      return 'primary';
    case 'TikTok':
      return 'default'; // cor preta
    case 'GA4':
      return 'error';
    default:
      return 'default';
  }
};

const renderTags = (tags,tipo_pixel) => {
  return tags.map((tag, index) => (
    <Chip
      key={index}
      label={tag}
       color={getColorForPixel(tipo_pixel)} 
      size="small"
      style={{ marginRight: 5, marginBottom: 5 }}
    />
  ));
};

function getLabelEvent(event){
  if(event.tipo_evento == 'scroll') return event.tipo_evento +" "+ event.scroll_percentage +"%"+ " - "+event.tipo_pixel
  if(event.tipo_evento == 'timer') return event.tipo_evento +" "+ event.delay +" min"+ " - "+event.tipo_pixel
  return event.tipo_evento + " - "+event.tipo_pixel
}

function getDomainsFromUrls(urls) {
  if (!urls || !Array.isArray(urls)) return [];
  return urls.map(url => {
    try {
      if (isValidDomain(url)) {
        return url;
      }
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (e) {
      console.error(`Erro ao tratar a URL: ${url}`, e);
      return null;
    }
  }).filter(domain => domain);
}

function isValidDomain(domain) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*([a-zA-Z0-9-_]+)\.([a-zA-Z]{2,11}?)(\/|:|$)/;
  return domainRegex.test(domain);
}