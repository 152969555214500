import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Container, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { login } from '../actions/authActions';

const Login = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Novo estado para controlar o loading
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email) {
      setErrorMessage('Por favor, insira um email.');
      return;
    }

    setLoading(true); // Setar o estado para true para começar o loading

    await dispatch(login(email, (error, data) => {
      setLoading(false); // Parar o loading depois da resposta do servidor
      console.log(error)
      if (error) {
        setErrorMessage(error); // Exibir mensagem de erro
      } else {
        navigate('/verify'); // Navegar para /verify se sucesso
      }
    }));
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}
              
              {loading ? (
                <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleLogin}
                  disabled={loading} // Desativa o botão enquanto loading é true
                >
                  Entrar
                </Button>
              )}

              <Button fullWidth variant="text" onClick={handleSignUpClick}>
                Inscrever-se gratuitamente
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Login;