import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ImportModal = ({ open, onClose, onImport }) => {
  const [jsonString, setJsonString] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Função para importar eventos a partir do JSON fornecido
  const handleImport = () => {
    setLoading(true); // Inicia o loading

    try {
      const events = JSON.parse(jsonString);

      // Verificar se é um array e se os itens do array têm uma propriedade id_unico
      if (Array.isArray(events) && events.every(event => event && event.id_unico)) {
        onImport(events); // Passa a lista de eventos importados
        setLoading(false); // Para o loading
        onClose(); // Fecha o modal
      } else {
        throw new Error('Formato de JSON inválido. Certifique-se de que é um array de eventos contendo a propriedade id_unico.');
      }
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false); // Para o loading em caso de erro
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const loadedJsonString = e.target.result;
          const events = JSON.parse(loadedJsonString);

          // Verificar se é um array e se os itens do array têm uma propriedade id_unico
          if (Array.isArray(events) && events.every(event => event && event.id_unico)) {
            onImport(events);
            onClose();
          } else {
            throw new Error('Formato de JSON inválido. Certifique-se de que é um array de eventos contendo a propriedade id_unico.');
          }
        } catch (error) {
          setErrorMessage(error.message);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Importar Eventos</DialogTitle>
      <DialogContent>
        <TextField
          label="JSON"
          fullWidth
          multiline
          value={jsonString}
          onChange={(e) => setJsonString(e.target.value)}
          variant="outlined"
        />
        <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
          Ou importe um arquivo JSON:
        </Typography>
        <input
          accept="application/json"
          style={{ display: 'none' }}
          id="upload-json-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="upload-json-file">
          <Tooltip title="Importar de Arquivo">
            <Button color="primary" component="span" startIcon={<UploadFileIcon />} style={{ marginTop: 10 }}>
              Importar por Arquivo
            </Button>
          </Tooltip>
        </label>
        {errorMessage && (
          <Alert severity="error" style={{ marginTop: 10 }}>
            {errorMessage}
          </Alert>
        )}
        {loading && (
          <CircularProgress style={{ marginTop: 10 }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleImport} color="primary" disabled={loading}>
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportModal;