
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Tooltip , MenuItem, Chip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';

const AttributeModal = ({ open, onClose, onSave, attribute, handleDelete, type_tracking}) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [make_hash, setMakeHash] = useState(false);
  const [tipo_data, setTipoData] = useState(false);

  useEffect(() => {
    if (attribute) {
      setName(attribute.name || '');
      setValue(attribute.value || '');
      setTipoData(attribute.tipo_data || "dinamic");
      setMakeHash(attribute.make_hash || false);
    } else {
      setName('');
      setValue('');
      setTipoData('dinamic');
    }
  }, [attribute]);

  const handleSave = () => {
    if(!name|| !value ) return alert("Add Nome e Valor")
    onSave({ name, value, id_inico: attribute?.id_inico || uuidv4(), make_hash, tipo_data });
    onClose();
  };

  const handleRemove = () => {
    if(window.confirm("Deseja remover?")){
        handleDelete(attribute.id_inico);
        onClose();
    }
    
  };
  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setMakeHash( checked  );
  };

  function normalizeString(str) {
    if(!str) return ""
    return str.trim()
      //.toLowerCase()                    // Converte para minúsculas
      .replace(/\s+/g, '')              // Remove espaços
      //.replace(/[^\w]/g, '');           // Remove caracteres especiais, mantendo apenas letras e números
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>{attribute ? 'Editar Atributo Webhook' : 'Adicionar Novo Atributo de Webhook'}</DialogTitle>
      <DialogContent style={{paddingTop:25}}>
       
<Autocomplete
freeSolo
  label="Nome do Atributo"
  onChange={(event, newValue) => {
    setName(normalizeString(newValue));
  }}
  value={name}
  options={[
    " -- FaceBook",
    "custom_data.currency",
    "custom_data.value",
    "custom_data.content_type",
    "user_data.client_ip_address",
    "user_data.em",
    "user_data.ph",
    "user_data.fn",
    "user_data.ln",
    "user_data.external_id",
    "user_data.country",

    " -- TikTok",
    "context.user.phone_number",
    "context.user.email",
    "context.user.external_id",
    "properties.content_type",
    "properties.currency",
    "properties.value",
    "context.ip",
    
    " -- Google Analytics 4",
    "client_id",
    "user_data.sha256_email_address",
    "user_data.sha256_phone_number",
    "user_data.address.sha256_first_name",
    "user_data.address.sha256_last_name",
    "user_data.address.country",
    "user_data.address.region",
    "user_data.address.city",
    "events[0].params.content",
    "events[0].params.currency",
    "events[0].params.value",
    "events[0].params.transaction_id",
    
    
  ]}
  renderInput={(params) => <TextField {...params}  onChange={(e) => setName(normalizeString(e.target.value))} label="Nome do Atributo" />}
/>



<TextField
              name="tipo_data"
              label="Defina se o dado vai ser fixo ou dinâmico"
              select
              fullWidth
              margin="normal"
              value={tipo_data  }
              helperText= {tipo_data == "dinamic"  ? "Adiciona o caminho do valor no JSON, ex: data.user.email " : "" } 

              onChange={(e) => setTipoData(e.target.value)}
            >
               <MenuItem value="fixed">Valor fixo</MenuItem>                 
               <MenuItem value="dinamic">Valor dinâmico enviado pelo Webhook</MenuItem>  

            
            </TextField>


        <TextField
          margin="normal"
          fullWidth
          label="Valor do Atributo"
          value={value}
          multiline
          onChange={(e) => {
            var value = e.target.value
            if(tipo_data !="fixed" && value) value = value.replace(/\s+/g, '')
            setValue(value)
          }}
          color= {type_tracking == "webhook" && make_hash ? "success" : "secondary" } 
          InputProps={{
            startAdornment: make_hash && (
              <div style={{ paddingRight:10}}>
                  <Tooltip title="Valor Convertido em Hash" >
                  <Chip
 color='success'
                    label={ "SHA256"}
                    size="small"
                  />
              </Tooltip>
              </div>
            ),
            endAdornment: (
              <div>
                {type_tracking == "webhook" && (
                  <Tooltip title="Valor Convertido em Hash" >
              <Switch name='mask' color= {type_tracking == "webhook" && make_hash ? "success" : "secondary" }  checked={make_hash} onChange={handleCheckboxChange} />
              </Tooltip>
                )}
              </div>
            ),
          }}


        />
      </DialogContent>
      <DialogActions>
        {attribute && (
          <Button onClick={handleRemove} color="secondary">
            Remover
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeModal;