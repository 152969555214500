
import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import authReducer from './reducers/authReducer';
import dataReducer from './reducers/dataReducer';
import pixelReducer from './reducers/pixelReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  pixel: pixelReducer, // Adiciona o novo reducer
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;